import React, { Component } from "react";
import {
  Col,
  Grid,
  Panel,
  Row,
} from "react-bootstrap";

export default class Home extends Component {
  
  render() {
    return (
      <div className="App">
        <Grid fluid={true}>
          <Row>
            <Col sm={6} md={6} lg={6}>
              <Panel>
                <Panel.Heading>
                  Game Setup
                </Panel.Heading>
                <Panel.Body>
                  <p>
                    1. Order of play is determined by the role of a single die.
                  </p>
                </Panel.Body>
              </Panel>
            </Col>
            <Col sm={6} md={6} lg={6}>
              <Panel>
                <Panel.Heading>
                  Weekly Play/League Scoring
                </Panel.Heading>
                <Panel.Body>
                  <p>
                  </p>
                </Panel.Body>
              </Panel>
            </Col>
            <Col sm={6} md={6} lg={6}>
              <Panel>
                <Panel.Heading>
                  Pace of Play
                </Panel.Heading>
                <Panel.Body>
                  <p>
                    1. A delay may be called by any player at any time, at which point a 60 second clock is initiated on the current player.
                  </p>
                </Panel.Body>
              </Panel>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}