import { FormGroup, ControlLabel, FormControl, Col, Panel, Grid, Row } from "react-bootstrap";
import React, { Component } from "react";
import { Auth } from "aws-amplify";
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import "./Profile.css";
import LoaderButton from "../components/LoaderButton"

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      infoLoading: true,
      infoFailed: false,
      currentUserId: "",
      firstName: "",
      lastName: "",
      isLoading: false,
      user: ""
    };
    
    Auth.currentAuthenticatedUser()
    .then(user => {
        console.log(user)
        this.setState({
            currentUserId: user.username,
            firstName: user.attributes.given_name,
            lastName: user.attributes.family_name,
            infoLoading: false,
            user: user
        })
    })
    .catch(err => console.log(err));
  }

  validateForm() {
    return this.state.firstName && this.state.lastName;
  }

  handleChange = event => {
    
    this.setState({
      [event.target.id]: event.target.value
    });
  }
  
  handleSubmit = async event => {
    event.preventDefault();
    this.setState({
        isLoading: true
    })
    var attributeList = [];
    var firstNameAttribute = {
        Name: "given_name",
        Value: this.state.firstName
    }
    var firstNameCognito = new AmazonCognitoIdentity.CognitoUserAttribute(firstNameAttribute)
    attributeList.push(firstNameCognito)
    var lastNameAttribute = {
        Name: "family_name",
        Value: this.state.lastName
    }
    var lastNameCognito = new AmazonCognitoIdentity.CognitoUserAttribute(lastNameAttribute)
    attributeList.push(lastNameCognito)

    Auth.currentAuthenticatedUser()
    .then(user => {
        var context = this
        user.updateAttributes(attributeList, function(err, data) {
            context.setState({
                isLoading: false
            })
        })
    })
    .catch(err => console.log(err));
  }

  render() {
    return (
      <Grid>
        <div>{this.state.leagueName}</div>
        <Row>
            <Col>
                <Panel>
                    <Panel.Heading>
                        User Info
                    </Panel.Heading>
                    <Panel.Body>
                        <div className="Profile">
                            <form onSubmit={this.handleSubmit}>
                            <FormGroup controlId="firstName" bsSize="large">
                                <ControlLabel>First Name</ControlLabel>
                                <FormControl
                                autoFocus
                                value={this.state.firstName}
                                onChange={this.handleChange}
                                data-cy="first-name"
                                />
                            </FormGroup>
                            <FormGroup controlId="lastName" bsSize="large">
                                <ControlLabel>Last Name</ControlLabel>
                                <FormControl
                                autoFocus
                                value={this.state.lastName}
                                onChange={this.handleChange}
                                data-cy="last-key"
                                />
                            </FormGroup>
                            <LoaderButton
                            block
                            bsSize="large"
                            disabled={!this.validateForm()}
                            type="submit"
                            isLoading={this.state.isLoading}
                            text="Update User Info"
                            loadingText="Updating…"
                            data-cy="update-info"
                            />
                            </form>
                        </div>
                    </Panel.Body>
                </Panel>
            </Col>
        </Row>
      </Grid>
    );
  }
}