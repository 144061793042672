import { PageHeader, Table, Col, Panel, Grid, Row, Glyphicon } from "react-bootstrap";
import React, { Component } from "react";
import axios from 'axios';
import { Auth } from "aws-amplify";
import "./League.css";
import config from "../config.js";

export default class League extends Component {
  constructor(props) {
    super(props);

    this.state = {
      infoLoading: true,
      infoFailed: false,
      membersLoading: true,
      membersFailed: false,
      leagueName: this.props.match.params.league,
      leagueInfo: {},
      leagueMembers: []
    };

    Auth.currentAuthenticatedUser()
    .then(user => {
        this.state.currentUserId = user.username

        axios.get(config.apiGateway.URL + "leagues/" + this.state.leagueName)
            .then(res => {
                this.setState({
                    infoLoading: false,
                    leagueInfo: res.data
                })
            }).catch(err => {
                this.setState({
                    infoLoading: false,
                    infoFailed: true
                })
            })

        axios.get(config.apiGateway.URL + "leagues/" + this.state.leagueName + "/users")
            .then(res => {
                console.log("Data")
                console.log(res.data)
                console.log("Body")
                console.log(res.data.body)
                this.setState({
                    membersLoading: false,
                    leagueMembers: res.data
                })
                console.log(this.state.leagueMembers)
            }).catch(err => {
                this.setState({
                    membersLoading: false,
                    membersFailed: true
                })
            })
    })
    .catch(err => console.log(err));
  }
  

  render() {
    return (
      <Grid>
        <PageHeader>
            {this.state.leagueName}
        </PageHeader>
        <Row>
            <Col sm={4} md={4} lg={4}>
                <Panel>
                    <Panel.Heading>
                        League Info
                    </Panel.Heading>
                    <Panel.Body>
                        {this.state.infoLoading
                            ? <Glyphicon glyph="refresh" className="spinning" />
                            : <Table responsive>
                                <tbody>
                                    <tr>
                                        <td>League Size:</td>
                                        <td>{this.state.leagueInfo.league_size}</td>
                                    </tr>
                                    <tr>
                                        <td>Number of Weeks:</td>
                                        <td>{this.state.leagueInfo.num_weeks}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        }
                    </Panel.Body>
                </Panel>
            </Col>
            <Col sm={4} md={4} lg={4}>
                <Panel>
                    <Panel.Heading>
                        League Members
                    </Panel.Heading>
                    <Panel.Body>
                        <Table responsive>
                            <tbody>
                            {this.state.leagueMembers.map((member) => {
                                return <tr key={member.primary_key}>
                                <td>{member.given_name + " " + member.family_name}</td>
                                </tr>
                            })}
                            </tbody>
                        </Table>
                        {this.state.membersLoading && <Glyphicon glyph="refresh" className="spinning" />}
                        {this.state.membersFailed && "Could not load league members data."}
                    </Panel.Body>
                </Panel>
            </Col>
            <Col sm={4} md={4} lg={4}>
                <Panel>
                    <Panel.Heading>
                        Schedule
                    </Panel.Heading>
                    <Panel.Body>
                    </Panel.Body>
                </Panel>
            </Col>
        </Row>
      </Grid>
    );
  }
}