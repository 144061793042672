import { Grid, Row, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import React, { Component } from "react";
import axios from 'axios';
import { Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import "./CreateLeague.css";
import config from "../config.js";

var typeWatch = function(){
    var timer = 0;
    return function(callback, ms){
        clearTimeout (timer);
        timer = setTimeout(callback, ms);
    }
}();    

export default class CreateLeague extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      getLeagueFailed: false,
      nameErrorClass: "",
      nameAvailabile: false,
      leagueName: "",
      leagueKey: "",
      leagueSize: "8",
      numWeeks: "8",
      currentUserId: ""
    };

    Auth.currentAuthenticatedUser()
    .then(user => {
        this.state.currentUserId = user.username
    })
    .catch(err => console.log(err));
  }
  

  validateForm() {
    return this.state.nameAvailable &&
    this.state.leagueName.length > 0 &&
    this.state.leagueSize.length > 0 &&
    this.state.numWeeks.length > 0 &&
    this.state.leagueKey.length > 0;
  }

  handleChange = event => {
    
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  checkLeague = event => {
      var leagueName = event.target.value
      var context = this
      this.setState({
        leagueName: event.target.value,
        nameAvailable: false
      });
      typeWatch(
        function(){
            axios.get(config.apiGateway.URL + "leagues/" + leagueName).then((response) => {
                context.setState({
                    nameErrorClass: "has-error",
                    
                })
            }).catch((error) => {
                if (error.response.status === 404)
                {
                    context.setState({
                        leagueName: leagueName,
                        nameAvailable: true,
                        nameErrorClass: ""
                    })
                }
                else {
                    context.setState({
                        getLeagueFailed: true
                    })
                }
                
            })
            
        },
        2000
      )
      
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({
        isLoading: true
    });
    axios.post(config.apiGateway.URL + "leagues", {
        leagueName: this.state.leagueName,
        leagueSize: this.state.leagueSize,
        numWeeks: this.state.numWeeks,
        leagueKey: this.state.leagueKey,
        currentUserId: this.state.currentUserId
    }).then((response) => {
        console.log(response);
        window.location.href = "/"
    }).catch((error) => {
        console.log(error);
        this.setState({
            isLoading: false
        })
    })
  }

  render() {
    return (
      <Grid>
        <Row>
            <div className="Create">
                <form onSubmit={this.handleSubmit}>
                <FormGroup controlId="leagueName" bsSize="large" className={this.state.nameErrorClass}>
                    <ControlLabel>League Name</ControlLabel>
                    <FormControl
                    autoFocus
                    value={this.state.leagueName}
                    onChange={this.checkLeague}
                    data-cy="league-name"
                    />
                </FormGroup>
                <FormGroup controlId="leagueKey" bsSize="large">
                    <ControlLabel>League Key</ControlLabel>
                    <FormControl
                    autoFocus
                    value={this.state.leagueKey}
                    onChange={this.handleChange}
                    data-cy="league-key"
                    />
                </FormGroup>
                <FormGroup controlId="leagueSize" bsSize="large">
                    <ControlLabel>League Size</ControlLabel>
                    <FormControl
                    componentClass="select"
                    placeholder="select"
                    value={this.state.leagueSize}
                    onChange={this.handleChange}
                    data-cy="league-size"
                    >
                        <option value="8">8</option>
                        <option value="12">12</option>
                        <option value="16">16</option>
                    </FormControl>
                </FormGroup>
                <FormGroup controlId="numWeeks" bsSize="large">
                    <ControlLabel>Number of Weeks</ControlLabel>
                    <FormControl
                    componentClass="select"
                    placeholder="select"
                    value={this.state.numWeeks}
                    onChange={this.handleChange}
                    data-cy="num-weeks"
                    >
                        <option value="8">8</option>
                        <option value="12">12</option>
                        <option value="16">16</option>
                    </FormControl>
                </FormGroup>
                <LoaderButton
                block
                bsSize="large"
                disabled={!this.validateForm()}
                type="submit"
                isLoading={this.state.isLoading}
                text="Create League"
                loadingText="Creating League…"
                data-cy="create-league"
                />
                </form>
            </div>
        </Row>
      </Grid>
    );
  }
}