import React, { Component } from "react";
import axios from "axios";
import {
  Button,
  Col,
  Grid,
  Panel,
  Row,
  Table,
  Glyphicon
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import logo from "./logo.svg";
import "./Home.css";
import config from "../config.js"

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      loadFailed: false,
      currentUserId: "",
      leagues: []
    };

  }
  componentDidMount() {
    Auth.currentAuthenticatedUser()
    .then(user => {
        this.setState({
          currentUserId: user.username
        })
        axios.get(config.apiGateway.URL + "users/" + this.state.currentUserId + "/leagues")
        .then(res => {
          const leagues = res.data;
          this.setState({
            leagues: leagues,
            isLoading: false
          })
        }).catch(err => {
          this.setState({
            isLoading: false,
            loadFailed: true
          })
        })
    })
    .catch(err => console.log(err));
    
    
  }

  render() {
    if (this.props.isAuthenticated) {
      return (
        <div className="App">
          <Grid fluid={true}>
            <Row>
              <Col sm={6} md={6} lg={6}>
                <Panel>
                  <Panel.Body>
                    <Link to="/leagues/join">
                      <Button>Join a league</Button>
                    </Link>
                  </Panel.Body>
                </Panel>
              </Col>
              <Col sm={6} md={6} lg={6}>
                <Panel>
                  <Panel.Body>
                    <Link to="/leagues/create">
                      <Button>Create a league</Button>
                    </Link>
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
            <Row>
              <Panel>
                <Panel.Heading>Your current leagues</Panel.Heading>
                <Panel.Body>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>League Name</th>
                        <th>Week</th>
                        <th>Place</th>
                        <th>League Size</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.leagues.map((league) => {
                        var leagueUrl = "/leagues/" + league.primary_key
                        return <tr key={league.primary_key}>
                          <td><Link to={leagueUrl}>{league.primary_key}</Link></td>
                          <td></td>
                          <td></td>
                          <td>{league.league_size}</td>
                        </tr>
                      })}
                    </tbody>
                  </Table>
                  {this.state.isLoading && <Glyphicon glyph="refresh" className="spinning" />}
                  {this.state.loadFailed && "Could not load league data."}
                </Panel.Body>
              </Panel>
            </Row>
          </Grid>
        </div>
      )
    }
    else {
      return (
        <Grid fluid className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              Welcome to Catan League
            </p>
            <Link to="/login">Login</Link>
            <Link to="/signup">Signup</Link>
          </header>
        </Grid>
      );  
    }
  }
}