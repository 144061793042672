const dev = {
    s3: {
        REGION: "us-east-1",
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_PEBMNhKPj",
        APP_CLIENT_ID: "3qv42htfev8klbr8sh7b8tbado",
        IDENTITY_POOL_ID: "us-east-1:17cbf704-a2a2-421d-9dec-95a3a71e369a"
    },
    apiGateway: {
      REGION: "YOUR_API_GATEWAY_REGION",
      URL: "http://localhost:3000/"
    }
};


const prod = {
    s3: {
        REGION: "us-east-1",
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_PEBMNhKPj",
        APP_CLIENT_ID: "3qv42htfev8klbr8sh7b8tbado",
        IDENTITY_POOL_ID: "us-east-1:17cbf704-a2a2-421d-9dec-95a3a71e369a"
    },
    apiGateway: {
      REGION: "YOUR_API_GATEWAY_REGION",
      URL: "https://api.catanleague.com/"
    }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
// Add common config values here
MAX_ATTACHMENT_SIZE: 5000000,
...config
};