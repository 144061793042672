import { Grid, Row, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import React, { Component } from "react";
import axios from 'axios';
import { Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import "./JoinLeague.css";
import config from "../config.js";

export default class JoinLeague extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      leagueName: "",
      leagueKey: "",
      leagueSize: "8",
      numWeeks: "8",
      currentUserId: ""
    };

    Auth.currentAuthenticatedUser()
    .then(user => {
        console.log(user)
        this.state.currentUserId = user.username
    })
    .catch(err => console.log(err));
  }

  validateForm() {
    return this.state.leagueName.length > 0 &&
    this.state.leagueSize.length > 0 &&
    this.state.numWeeks.length > 0 &&
    this.state.leagueKey.length > 0;
  }

  handleChange = event => {
    
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({
        isLoading: true
    });
    axios.post(config.apiGateway.URL + "users/" + this.state.currentUserId + "/leagues", {
        leagueName: this.state.leagueName,
        leagueKey: this.state.leagueKey,
        currentUserId: this.state.currentUserId
    }).then((response) => {
        console.log(response);
        window.location.href = "/"
    }).catch((error) => {
        console.log(error);
        this.setState({
            isLoading: false
        })
    })
  }

  render() {
    return (
      <Grid>
        <Row>
            <div className="Create">
                <form onSubmit={this.handleSubmit}>
                <FormGroup controlId="leagueName" bsSize="large">
                    <ControlLabel>League Name</ControlLabel>
                    <FormControl
                    autoFocus
                    value={this.state.leagueName}
                    onChange={this.handleChange}
                    data-cy="league-name"
                    />
                </FormGroup>
                <FormGroup controlId="leagueKey" bsSize="large">
                    <ControlLabel>League Key</ControlLabel>
                    <FormControl
                    autoFocus
                    value={this.state.leagueKey}
                    onChange={this.handleChange}
                    data-cy="league-key"
                    />
                </FormGroup>
                <LoaderButton
                block
                bsSize="large"
                disabled={!this.validateForm()}
                type="submit"
                isLoading={this.state.isLoading}
                text="Join League"
                loadingText="Joining League…"
                data-cy="join-league"
                />
                </form>
            </div>
        </Row>
      </Grid>
    );
  }
}