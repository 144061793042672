import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";
import NotFound from "./containers/NotFound";
import Signup from "./containers/Signup";
import Rules from "./containers/Rules";
import CreateLeague from "./containers/CreateLeague";
import JoinLeague from "./containers/JoinLeague";
import AppliedRoute from "./components/AppliedRoute";
import League from "./containers/League";
import Profile from "./containers/Profile";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/login" exact component={Login} props={childProps} />
    <AppliedRoute path="/signup" exact component={Signup} props={childProps} />
    <AppliedRoute path="/rules" exact component={Rules} props={childProps} />
    <AppliedRoute path="/leagues/create" exact component={CreateLeague} props={childProps} />
    <AppliedRoute path="/leagues/join" exact component={JoinLeague} props={childProps} />
    <AppliedRoute path="/leagues/:league" exact component={League} props={childProps} />
    <AppliedRoute path="/profile/:user" exact component={Profile} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;
  